import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalEventsService implements OnDestroy {
    private subscriptions: Subscription[] = [];
    private subjectMousedownEvent = new Subject<MouseEvent>();
    private subjectKeydownEvent = new Subject<KeyboardEvent>();
    private subjectKeydownEventOnlyThis = new Subject<KeyboardEvent>();
    private subjectKeyupEvent = new Subject<KeyboardEvent>();

    private isKeydownEventOnlyOneElement: boolean = false;

    constructor() {
        this.enableGlobalClickEvent().enableGlobalKeydownEvent().enableGlobalKeyupEvent();
    }

    public getGlobalMouseDownEvent(): Observable<MouseEvent> {
        return this.subjectMousedownEvent.asObservable();
    }

    public getGlobalKeydownEvent(): Observable<KeyboardEvent> {
        return this.subjectKeydownEvent.asObservable();
    }

    public getGlobalKeyupEvent(): Observable<KeyboardEvent> {
        return this.subjectKeyupEvent.asObservable();
    }

    private enableGlobalClickEvent(): GlobalEventsService {
        let clickEvent: Subscription = fromEvent(window, 'mousedown').subscribe((event: Event) => {
            (event instanceof MouseEvent) && this.subjectMousedownEvent.next(event);
        });
        this.subscriptions.push(clickEvent);
        return this;
    }

    private enableGlobalKeyupEvent(): GlobalEventsService {
        let keyupEvent: Subscription = fromEvent(window, 'keyup').subscribe((event: Event) => {
            if (!(event instanceof KeyboardEvent)) {
                return;
            }
            this.subjectKeyupEvent.next(event);
        });
        this.subscriptions.push(keyupEvent);
        return this;
    }

    private enableGlobalKeydownEvent(): GlobalEventsService {
        let keydownEvent: Subscription = fromEvent(window, 'keydown').subscribe((event: Event) => {
            if (!(event instanceof KeyboardEvent)) {
                return;
            }
            if (this.isKeydownEventOnlyOneElement) {
                this.subjectKeydownEventOnlyThis.next(event);
                return;
            }
            this.customGlobalKeydownEvent(event);
        });
        this.subscriptions.push(keydownEvent);
        return this;
    }

    public customGlobalKeydownEvent(event: KeyboardEvent): void {
        this.subjectKeydownEvent.next(event);
    }

    public disableOnlyThisMode(): GlobalEventsService {
        this.isKeydownEventOnlyOneElement = false;
        return this;
    }

    public enableOnlyThisMode(): GlobalEventsService {
        this.isKeydownEventOnlyOneElement = true;
        return this;
    }

    public getGlobalKeydownEventOnlyThis(flag: boolean = true): Observable<KeyboardEvent> {
        if (this.isKeydownEventOnlyOneElement) {
            throw Error('error. only this mode enable.');
        }
        !!flag && this.enableOnlyThisMode();
        return this.subjectKeydownEventOnlyThis.asObservable();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
